import { makeStyles, useTheme } from "@material-ui/core/styles"
import useHeight from "../videochat/hooks/useHeight/useHeight"
import SEO from "../../components/seo"

import MenuWithLogo from "../menu/MenuWithLogo"
import React, { useEffect } from "react"
import { Typography } from "@material-ui/core"
import { AuthenticatedOrSSRRoute } from "../routing/Routes"
import withLocation from "../../components/withLocation"
import { useDispatch, useSelector } from "react-redux"
import { RootState } from "../../redux/store"
import { showDashboard, clean } from "./membershipSlice"
import BackdropLoader from "../../components/BackdropLoader"
import StyledSnackbar from "../../components/StyledSnackbar"
import ActivateMembership from "../browse/ActivateMembership/ActivateMembership"
import { navigate } from "gatsby"
import { WORKOUTS_HOME } from "../routing/Locations"

const useStyles = makeStyles((theme) => ({
  root: {
    display: "block",
    backgroundColor: "white",
    width: "100%",
    height: "100%",
    minHeight: "100vh",
  },
  body: {
    paddingTop: theme.spacing(5),
    maxWidth: "90%",
    color: "black",
    marginLeft: "auto",
    marginRight: "auto",
    height: "100%"
  },
  content: {
    flexGrow: 1,
    [theme.breakpoints.down("xs")]: {
      flexGrow: 0
    }
  },

  date: {
    color: "white",
    textTransform: "uppercase",
    fontSize: "11rem",
    marginTop: theme.spacing(2),
    [theme.breakpoints.up("xl")]: {
      fontSize: "16rem",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "6.5rem",
    }
  }

}))


function TempMembership() {
  const classes = useStyles()
  const height = useHeight()

  const membershipState = useSelector((state: RootState) => state.membership)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(showDashboard())
    return () => {
      dispatch(clean())
    }
  }, [])


  return  <div style={{ minHeight: height == "0px" ? "100vh" : height }} className={classes.root}>
    <SEO title={"Membership"}/>

    <MenuWithLogo memberMenu={true} showWelcome={false} blackIcon={true}/>

    <div className={classes.body}>
      <Typography variant={"h1"} align={"center"}>MEMBERSHIP</Typography>
    </div>

    <BackdropLoader show={membershipState.loading}/>
    {membershipState.inactiveMember && <ActivateMembership cleanup={() => {
      navigate(WORKOUTS_HOME)
    }}/>}
    {membershipState.error && <StyledSnackbar show={true} severity={"error"} message={membershipState.error.message}
                              showTime={membershipState.error.errorTime}/>}

  </div>

}



export default function TempMembershipWrapper() {
  const membership = withLocation(TempMembership)
  return <AuthenticatedOrSSRRoute component={membership}/>
}